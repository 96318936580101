
import { ChangeEventHandler } from 'react';
import { cn } from '../helpers';

interface Props {
  id?: string;
  className?: string;
  value: string;
  values: { name: string; value: string }[];
  onChange: ChangeEventHandler<HTMLSelectElement>;
}

export default function Select(props: Props) {
  return (
    <select
      className={cn(styles.select, props.className)}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
    >
      {props.values.map((option) => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>
  );
}

const styles = require('./Select-styles.module.scss');
