
import { ReactNode, useRef } from 'react';
import { Plus } from 'react-feather';
import { useParams } from 'react-router';
import * as listActions from '../actions/list';
import { useLists } from '../hooks/useLists';
import { RouteParams } from '../types';
import IconButton from './IconButton';
import List from './List';

export default function Lists() {
  const { teamId = '-' } = useParams<RouteParams>();
  const lists = useLists();
  const listsRef = useRef<HTMLUListElement>(null);

  async function createList() {
    await listActions.createList(teamId, {
      order: Date.now(),
    });

    if (listsRef.current) {
      const lastChild = listsRef.current.lastElementChild;
      if (lastChild) {
        const input = lastChild.querySelector('textarea');
        input && (input as HTMLElement).focus();
      }
    }
  }

  async function moveListDown(movedIndex: number) {
    if (movedIndex >= lists.length - 1) return;

    const movedList = lists[movedIndex];
    const movedListOrder = movedList.order;
    const swappedList = lists[movedIndex + 1];
    const swappedListOrder = swappedList.order;

    listActions.reorderLists(teamId, [
      { ...movedList, order: swappedListOrder },
      { ...swappedList, order: movedListOrder },
    ]);
  }

  async function moveListUp(movedIndex: number) {
    if (movedIndex <= 0) return;

    const movedList = lists[movedIndex];
    const movedListOrder = movedList.order;
    const swappedList = lists[movedIndex - 1];
    const swappedListOrder = swappedList.order;

    listActions.reorderLists(teamId, [
      { ...movedList, order: swappedListOrder },
      { ...swappedList, order: movedListOrder },
    ]);
  }

  let content: ReactNode = null;
  if (lists.length) {
    content = lists.map((list, index) => (
      <List
        key={list.listId}
        listId={list.listId}
        title={list.title}
        index={index}
        moveDown={moveListDown}
        moveUp={moveListUp}
      />
    ));
  }

  return (
    <section className={styles.lists}>
      <header className={styles.header}>
        <h1 className={styles.heading}>Lists</h1>
        <IconButton label="New list" icon={<Plus />} headerButton={true} onClick={createList} />
      </header>

      <ul className={styles.listOfLists} ref={listsRef}>
        {content}
      </ul>
    </section>
  );
}

const styles = require('./Lists-styles.module.scss');
