
import { MouseEventHandler, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  href?: string;
  leftIcon?: ReactNode;
  onClick?: MouseEventHandler;
  children?: ReactNode;
}

export default function DropdownItem(props: Props) {
  const { leftIcon, href, children, ...rest } = props;

  return (
    <li className={styles.item}>
      {href ? (
        <Link {...rest} className={styles.button} to={href}>
          {leftIcon && <span className={styles.leftIcon}>{leftIcon}</span>}
          {children}
        </Link>
      ) : (
        <button {...rest} className={styles.button}>
          {leftIcon && <span className={styles.leftIcon}>{leftIcon}</span>}
          {children}
        </button>
      )}
    </li>
  );
}

const styles = require('./DropdownItem-styles.module.scss');
