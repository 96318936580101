export const colors = [
  { name: 'mist blue', value: '#646d7e' },
  { name: 'marble blue', value: '#566d7e' },
  { name: 'slate blue', value: '#737ca1' },
  { name: 'steel blue', value: '#4863a0' },
  { name: 'blue jay', value: '#2b547e' },
  { name: 'dark slate blue', value: '#2b3856' },
  { name: 'midnight blue', value: '#151b54' },
  { name: 'navy blue', value: '#000080' },
  { name: 'blue whale', value: '#342d7e' },
  { name: 'lapis blue', value: '#15317e' },
  { name: 'denim dark blue', value: '#151b8d' },
  { name: 'earth blue', value: '#0000a0' },
  { name: 'cobalt blue', value: '#0020c2' },
  { name: 'blueberry blue', value: '#0041c2' },
  { name: 'sapphire blue', value: '#2554c7' },
  { name: 'blue eyes', value: '#1569c7' },
  { name: 'royal blue', value: '#2b60de' },
  { name: 'blue orchid', value: '#1f45fc' },
  { name: 'blue lotus', value: '#6960ec' },
  { name: 'light slate blue', value: '#736aff' },
  { name: 'windows blue', value: '#357ec7' },
  { name: 'glacial blue ice', value: '#368bc1' },
  { name: 'silk blue', value: '#488ac7' },
  { name: 'blue ivy', value: '#3090c7' },
  { name: 'blue koi', value: '#659ec7' },
  { name: 'columbia blue', value: '#87afc7' },
  { name: 'baby blue', value: '#95b9c7' },
  { name: 'light steel blue', value: '#728fce' },
  { name: 'ocean blue', value: '#2b65ec' },
  { name: 'blue ribbon', value: '#306eff' },
  { name: 'blue dress', value: '#157dec' },
  { name: 'dodger blue', value: '#1589ff' },
  { name: 'cornflower blue', value: '#6495ed' },
  { name: 'sky blue', value: '#6698ff' },
  { name: 'butterfly blue', value: '#38acec' },
  { name: 'iceberg', value: '#56a5ec' },
  { name: 'crystal blue', value: '#5cb3ff' },
  { name: 'deep sky blue', value: '#3bb9ff' },
  { name: 'denim blue', value: '#79baec' },
  { name: 'light sky blue', value: '#82cafa' },
  { name: 'day sky blue', value: '#82caff' },
  { name: 'jeans blue', value: '#a0cfec' },
  { name: 'blue angel', value: '#b7ceec' },
  { name: 'pastel blue', value: '#b4cfec' },
  { name: 'sea blue', value: '#c2dfff' },
  { name: 'powder blue', value: '#c6deff' },
  { name: 'coral blue', value: '#afdcec' },
  { name: 'light blue', value: '#addfff' },
  { name: 'robin egg blue', value: '#bdedff' },
  { name: 'pale blue lily', value: '#cfecec' },
  { name: 'light cyan', value: '#e0ffff' },
  { name: 'water', value: '#ebf4fa' },
  { name: 'aliceblue', value: '#f0f8ff' },
  { name: 'azure', value: '#f0ffff' },
  { name: 'light slate', value: '#ccffff' },
  { name: 'light aquamarine', value: '#93ffe8' },
  { name: 'electric blue', value: '#9afeff' },
  { name: 'aquamarine', value: '#7fffd4' },
  { name: 'cyan or aqua', value: '#00ffff' },
  { name: 'tron blue', value: '#7dfdfe' },
  { name: 'blue zircon', value: '#57feff' },
  { name: 'blue lagoon', value: '#8eebec' },
  { name: 'celeste', value: '#50ebec' },
  { name: 'blue diamond', value: '#4ee2ec' },
  { name: 'tiffany blue', value: '#81d8d0' },
  { name: 'cyan opaque', value: '#92c7c7' },
  { name: 'blue hosta', value: '#77bfc7' },
  { name: 'northern lights blue', value: '#78c7c7' },
  { name: 'medium turquoise', value: '#48cccd' },
  { name: 'turquoise', value: '#43c6db' },
  { name: 'jellyfish', value: '#46c7c7' },
  { name: 'blue green', value: '#7bccb5' },
  { name: 'macaw blue green', value: '#43bfc7' },
  { name: 'light sea green', value: '#3ea99f' },
  { name: 'dark turquoise', value: '#3b9c9c' },
  { name: 'sea turtle green', value: '#438d80' },
  { name: 'medium aquamarine', value: '#348781' },
  { name: 'greenish blue', value: '#307d7e' },
  { name: 'grayish turquoise', value: '#5e7d7e' },
  { name: 'beetle green', value: '#4c787e' },
  { name: 'teal', value: '#008080' },
  { name: 'sea green', value: '#4e8975' },
  { name: 'camouflage green', value: '#78866b' },
  { name: 'sage green', value: '#848b79' },
  { name: 'hazel green', value: '#617c58' },
  { name: 'venom green', value: '#728c00' },
  { name: 'fern green', value: '#667c26' },
  { name: 'dark forest green', value: '#254117' },
  { name: 'medium sea green', value: '#306754' },
  { name: 'medium forest green', value: '#347235' },
  { name: 'seaweed green', value: '#437c17' },
  { name: 'pine green', value: '#387c44' },
  { name: 'jungle green', value: '#347c2c' },
  { name: 'shamrock green', value: '#347c17' },
  { name: 'medium spring green', value: '#348017' },
  { name: 'forest green', value: '#4e9258' },
  { name: 'green onion', value: '#6aa121' },
  { name: 'spring green', value: '#4aa02c' },
  { name: 'lime green', value: '#41a317' },
  { name: 'clover green', value: '#3ea055' },
  { name: 'green snake', value: '#6cbb3c' },
  { name: 'alien green', value: '#6cc417' },
  { name: 'green apple', value: '#4cc417' },
  { name: 'yellow green', value: '#52d017' },
  { name: 'kelly green', value: '#4cc552' },
  { name: 'zombie green', value: '#54c571' },
  { name: 'frog green', value: '#99c68e' },
  { name: 'green peas', value: '#89c35c' },
  { name: 'dollar bill green', value: '#85bb65' },
  { name: 'dark sea green', value: '#8bb381' },
  { name: 'iguana green', value: '#9cb071' },
  { name: 'avocado green', value: '#b2c248' },
  { name: 'pistachio green', value: '#9dc209' },
  { name: 'salad green', value: '#a1c935' },
  { name: 'hummingbird green', value: '#7fe817' },
  { name: 'nebula green', value: '#59e817' },
  { name: 'stoplight go green', value: '#57e964' },
  { name: 'algae green', value: '#64e986' },
  { name: 'jade green', value: '#5efb6e' },
  { name: 'green', value: '#00ff00' },
  { name: 'emerald green', value: '#5ffb17' },
  { name: 'lawn green', value: '#87f717' },
  { name: 'chartreuse', value: '#8afb17' },
  { name: 'dragon green', value: '#6afb92' },
  { name: 'mint green', value: '#98ff98' },
  { name: 'green thumb', value: '#b5eaaa' },
  { name: 'light jade', value: '#c3fdb8' },
  { name: 'tea green', value: '#ccfb5d' },
  { name: 'green yellow', value: '#b1fb17' },
  { name: 'slime green', value: '#bce954' },
  { name: 'goldenrod', value: '#edda74' },
  { name: 'harvest gold', value: '#ede275' },
  { name: 'sun yellow', value: '#ffe87c' },
  { name: 'yellow', value: '#ffff00' },
  { name: 'corn yellow', value: '#fff380' },
  { name: 'parchment', value: '#ffffc2' },
  { name: 'cream', value: '#ffffcc' },
  { name: 'lemon chiffon', value: '#fff8c6' },
  { name: 'cornsilk', value: '#fff8dc' },
  { name: 'beige', value: '#f5f5dc' },
  { name: 'blonde', value: '#fbf6d9' },
  { name: 'antiquewhite', value: '#faebd7' },
  { name: 'champagne', value: '#f7e7ce' },
  { name: 'blanchedalmond', value: '#ffebcd' },
  { name: 'vanilla', value: '#f3e5ab' },
  { name: 'tan brown', value: '#ece5b6' },
  { name: 'peach', value: '#ffe5b4' },
  { name: 'mustard', value: '#ffdb58' },
  { name: 'rubber ducky yellow', value: '#ffd801' },
  { name: 'bright gold', value: '#fdd017' },
  { name: 'golden brown', value: '#eac117' },
  { name: 'macaroni and cheese', value: '#f2bb66' },
  { name: 'saffron', value: '#fbb917' },
  { name: 'beer', value: '#fbb117' },
  { name: 'cantaloupe', value: '#ffa62f' },
  { name: 'bee yellow', value: '#e9ab17' },
  { name: 'brown sugar', value: '#e2a76f' },
  { name: 'burlywood', value: '#deb887' },
  { name: 'deep peach', value: '#ffcba4' },
  { name: 'ginger brown', value: '#c9be62' },
  { name: 'school bus yellow', value: '#e8a317' },
  { name: 'sandy brown', value: '#ee9a4d' },
  { name: 'fall leaf brown', value: '#c8b560' },
  { name: 'orange gold', value: '#d4a017' },
  { name: 'sand', value: '#c2b280' },
  { name: 'cookie brown', value: '#c7a317' },
  { name: 'caramel', value: '#c68e17' },
  { name: 'brass', value: '#b5a642' },
  { name: 'khaki', value: '#ada96e' },
  { name: 'camel brown', value: '#c19a6b' },
  { name: 'bronze', value: '#cd7f32' },
  { name: 'tiger orange', value: '#c88141' },
  { name: 'cinnamon', value: '#c58917' },
  { name: 'bullet shell', value: '#af9b60' },
  { name: 'dark goldenrod', value: '#af7817' },
  { name: 'copper', value: '#b87333' },
  { name: 'wood', value: '#966f33' },
  { name: 'oak brown', value: '#806517' },
  { name: 'moccasin', value: '#827839' },
  { name: 'army brown', value: '#827b60' },
  { name: 'sandstone', value: '#786d5f' },
  { name: 'mocha', value: '#493d26' },
  { name: 'taupe', value: '#483c32' },
  { name: 'coffee', value: '#6f4e37' },
  { name: 'brown bear', value: '#835c3b' },
  { name: 'red dirt', value: '#7f5217' },
  { name: 'sepia', value: '#7f462c' },
  { name: 'orange salmon', value: '#c47451' },
  { name: 'rust', value: '#c36241' },
  { name: 'red fox', value: '#c35817' },
  { name: 'chocolate', value: '#c85a17' },
  { name: 'sedona', value: '#cc6600' },
  { name: 'papaya orange', value: '#e56717' },
  { name: 'halloween orange', value: '#e66c2c' },
  { name: 'pumpkin orange', value: '#f87217' },
  { name: 'construction cone orange', value: '#f87431' },
  { name: 'sunrise orange', value: '#e67451' },
  { name: 'mango orange', value: '#ff8040' },
  { name: 'dark orange', value: '#f88017' },
  { name: 'coral', value: '#ff7f50' },
  { name: 'basket ball orange', value: '#f88158' },
  { name: 'light salmon', value: '#f9966b' },
  { name: 'tangerine', value: '#e78a61' },
  { name: 'dark salmon', value: '#e18b6b' },
  { name: 'light coral', value: '#e77471' },
  { name: 'bean red', value: '#f75d59' },
  { name: 'valentine red', value: '#e55451' },
  { name: 'shocking orange', value: '#e55b3c' },
  { name: 'red', value: '#ff0000' },
  { name: 'scarlet', value: '#ff2400' },
  { name: 'ruby red', value: '#f62217' },
  { name: 'ferrari red', value: '#f70d1a' },
  { name: 'fire engine red', value: '#f62817' },
  { name: 'lava red', value: '#e42217' },
  { name: 'love red', value: '#e41b17' },
  { name: 'grapefruit', value: '#dc381f' },
  { name: 'chestnut red', value: '#c34a2c' },
  { name: 'cherry red', value: '#c24641' },
  { name: 'mahogany', value: '#c04000' },
  { name: 'chilli pepper', value: '#c11b17' },
  { name: 'cranberry', value: '#9f000f' },
  { name: 'red wine', value: '#990012' },
  { name: 'burgundy', value: '#8c001a' },
  { name: 'chestnut', value: '#954535' },
  { name: 'blood red', value: '#7e3517' },
  { name: 'sienna', value: '#8a4117' },
  { name: 'sangria', value: '#7e3817' },
  { name: 'firebrick', value: '#800517' },
  { name: 'maroon', value: '#810541' },
  { name: 'plum pie', value: '#7d0541' },
  { name: 'velvet maroon', value: '#7e354d' },
  { name: 'plum velvet', value: '#7d0552' },
  { name: 'rosy finch', value: '#7f4e52' },
  { name: 'puce', value: '#7f5a58' },
  { name: 'dull purple', value: '#7f525d' },
  { name: 'rosy brown', value: '#b38481' },
  { name: 'khaki rose', value: '#c5908e' },
  { name: 'pink bow', value: '#c48189' },
  { name: 'lipstick pink', value: '#c48793' },
  { name: 'rose', value: '#e8adaa' },
  { name: 'rose gold', value: '#ecc5c0' },
  { name: 'desert sand', value: '#edc9af' },
  { name: 'pig pink', value: '#fdd7e4' },
  { name: 'cotton candy', value: '#fcdfff' },
  { name: 'pink bubblegum', value: '#ffdfdd' },
  { name: 'misty rose', value: '#fbbbb9' },
  { name: 'pink', value: '#faafbe' },
  { name: 'light pink', value: '#faafba' },
  { name: 'flamingo pink', value: '#f9a7b0' },
  { name: 'pink rose', value: '#e7a1b0' },
  { name: 'pink daisy', value: '#e799a3' },
  { name: 'cadillac pink', value: '#e38aae' },
  { name: 'carnation pink', value: '#f778a1' },
  { name: 'blush red', value: '#e56e94' },
  { name: 'hot pink', value: '#f660ab' },
  { name: 'watermelon pink', value: '#fc6c85' },
  { name: 'violet red', value: '#f6358a' },
  { name: 'deep pink', value: '#f52887' },
  { name: 'pink cupcake', value: '#e45e9d' },
  { name: 'pink lemonade', value: '#e4287c' },
  { name: 'neon pink', value: '#f535aa' },
  { name: 'magenta', value: '#ff00ff' },
  { name: 'dimorphotheca magenta', value: '#e3319d' },
  { name: 'bright neon pink', value: '#f433ff' },
  { name: 'pale violet red', value: '#d16587' },
  { name: 'tulip pink', value: '#c25a7c' },
  { name: 'medium violet red', value: '#ca226b' },
  { name: 'rogue pink', value: '#c12869' },
  { name: 'burnt pink', value: '#c12267' },
  { name: 'bashful pink', value: '#c25283' },
  { name: 'dark carnation pink', value: '#c12283' },
  { name: 'plum', value: '#b93b8f' },
  { name: 'viola purple', value: '#7e587e' },
  { name: 'purple iris', value: '#571b7e' },
  { name: 'plum purple', value: '#583759' },
  { name: 'indigo', value: '#4b0082' },
  { name: 'purple monster', value: '#461b7e' },
  { name: 'purple haze', value: '#4e387e' },
  { name: 'eggplant', value: '#614051' },
  { name: 'grape', value: '#5e5a80' },
  { name: 'purple jam', value: '#6a287e' },
  { name: 'dark orchid', value: '#7d1b7e' },
  { name: 'purple flower', value: '#a74ac7' },
  { name: 'medium orchid', value: '#b048b5' },
  { name: 'purple amethyst', value: '#6c2dc7' },
  { name: 'dark violet', value: '#842dce' },
  { name: 'violet', value: '#8d38c9' },
  { name: 'purple sage bush', value: '#7a5dc7' },
  { name: 'lovely purple', value: '#7f38ec' },
  { name: 'purple', value: '#8e35ef' },
  { name: 'aztech purple', value: '#893bff' },
  { name: 'medium purple', value: '#8467d7' },
  { name: 'jasmine purple', value: '#a23bec' },
  { name: 'purple daffodil', value: '#b041ff' },
  { name: 'tyrian purple', value: '#c45aec' },
  { name: 'crocus purple', value: '#9172ec' },
  { name: 'purple mimosa', value: '#9e7bff' },
  { name: 'heliotrope purple', value: '#d462ff' },
  { name: 'crimson', value: '#e238ec' },
  { name: 'purple dragon', value: '#c38ec7' },
  { name: 'lilac', value: '#c8a2c8' },
  { name: 'blush pink', value: '#e6a9ec' },
  { name: 'mauve', value: '#e0b0ff' },
  { name: 'wisteria purple', value: '#c6aec7' },
  { name: 'blossom pink', value: '#f9b7ff' },
  { name: 'thistle', value: '#d2b9d3' },
  { name: 'periwinkle', value: '#e9cfec' },
  { name: 'lavender pinocchio', value: '#ebdde2' },
  { name: 'lavender blue', value: '#e3e4fa' },
  { name: 'pearl', value: '#fdeef4' },
  { name: 'seashell', value: '#fff5ee' },
  { name: 'milk white', value: '#fefcff' },
  { name: 'white', value: '#ffffff' },
  { name: 'black', value: '#000000' },
  { name: 'night', value: '#0c090a' },
  { name: 'gunmetal', value: '#2c3539' },
  { name: 'midnight', value: '#2b1b17' },
  { name: 'charcoal', value: '#34282c' },
  { name: 'dark slate grey', value: '#25383c' },
  { name: 'oil', value: '#3b3131' },
  { name: 'black cat', value: '#413839' },
  { name: 'iridium', value: '#3d3c3a' },
  { name: 'black eel', value: '#463e3f' },
  { name: 'black cow', value: '#4c4646' },
  { name: 'gray wolf', value: '#504a4b' },
  { name: 'vampire gray', value: '#565051' },
  { name: 'gray dolphin', value: '#5c5858' },
  { name: 'carbon gray', value: '#625d5d' },
  { name: 'ash gray', value: '#666362' },
  { name: 'cloudy gray', value: '#6d6968' },
  { name: 'smokey gray', value: '#726e6d' },
  { name: 'gray', value: '#736f6e' },
  { name: 'granite', value: '#837e7c' },
  { name: 'battleship gray', value: '#848482' },
  { name: 'gray cloud', value: '#b6b6b4' },
  { name: 'gray goose', value: '#d1d0ce' },
  { name: 'platinum', value: '#e5e4e2' },
  { name: 'metallic silver', value: '#bcc6cc' },
  { name: 'blue gray', value: '#98afc7' },
  { name: 'light slate gray', value: '#6d7b8d' },
  { name: 'slate gray', value: '#657383' },
  { name: 'jet gray', value: '#616d7e' },
];
