
import { ReactNode, useEffect } from 'react';
import { useNotification } from '../hooks/useNotification';

interface Props {
  flavor: 'error';
  children: ReactNode;
}

export default function Notification(props: Props) {
  const [, setNotification] = useNotification();

  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification(null);
    }, 4000);

    return () => clearTimeout(timer);
  }, [setNotification]);

  return (
    <div className={styles.alert} role="alert">
      {props.children}
    </div>
  );
}

const styles = require('./Notification-styles.module.scss');
