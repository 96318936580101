
import { pairistVersion } from '../config';

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div>Pairist v{pairistVersion}</div>
      <div>
        <a href="https://github.com/pivotal-cf/pairist" target="_blank" rel="noreferrer">
          View on GitHub
        </a>
      </div>
    </footer>
  );
}

const styles = require('./Footer-styles.module.scss');
