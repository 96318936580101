
import { ReactNode } from 'react';

interface Props {
  error?: string;
  children: ReactNode;
}

export default function ModalFooter(props: Props) {
  return (
    <footer className={styles.footer}>
      {props.error && (
        <div className={styles.error} role="alert">
          {props.error}
        </div>
      )}
      <div className={styles.buttons}>{props.children}</div>
    </footer>
  );
}

const styles = require('./ModalFooter-styles.module.scss');
