
import { forwardRef, MouseEventHandler, ReactElement, ReactNode } from 'react';
import { cn } from '../helpers';

interface Props {
  bold?: boolean;
  imageURL?: string;
  imageHash?: string;
  disabled?: boolean;
  icon?: ReactElement;
  onClick?: MouseEventHandler;
  content: ReactNode;
}

export default forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { bold, imageURL, imageHash, content, icon, ...restProps } = props;
  const className = cn(styles.headerButton, props.bold && styles.bold);

  return (
    <button {...restProps} className={className} ref={ref}>
      {imageURL ? (
        <img className={styles.image} src={imageURL} alt="" />
      ) : imageHash ? (
        <svg className={styles.image} width="80" height="80" data-jdenticon-value={imageHash} />
      ) : null}
      <div>{content}</div>
      {icon && (
        <div className={styles.icon} aria-hidden="true">
          {icon}
        </div>
      )}
    </button>
  );
});

const styles = require('./HeaderButton-styles.module.scss');
