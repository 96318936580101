
import Button from './Button';

interface Props {}

export default function NewUser(props: Props) {
  return (
    <div className={styles.newUser}>
      <h2>Welcome to Pairist! 👋</h2>

      <p>
        We've sent you an email to verify your account. Please click the link in the email, then
        continue below. <b>Note: it may be in your spam folder.</b>
      </p>

      <Button bold flavor="confirm" onClick={() => window.location.reload()}>
        Continue
      </Button>
    </div>
  );
}

const styles = require('./NewUser-styles.module.scss');
