
import { useEffect, useRef, useState } from 'react';
import { emojiList } from '../emojis';
import { cn } from '../helpers';

interface Props {
  onSelect: (emojiName: string | null) => void;
}

export default function EmojiMenu(props: Props) {
  const [search, setSearch] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { onSelect } = props;
  const searchTerms = search.split(/\s+/);

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  useEffect(() => {
    const handleWindowClick = (evt: MouseEvent) => {
      const classList = document.activeElement?.classList;
      if (classList && classList.contains('emojiSearchInput')) return;
      if (containerRef.current && !containerRef.current.contains(evt.target as Node)) {
        onSelect(null);
      }
    };

    window.addEventListener('click', handleWindowClick);

    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, [onSelect]);

  return (
    <div className={styles.emojiBox} ref={containerRef}>
      <input
        type="text"
        ref={inputRef}
        value={search}
        onChange={(evt) => setSearch(evt.target.value.toLowerCase())}
        className={cn(styles.searchInput, 'emojiSearchInput')}
        placeholder="Search emojis..."
      />

      <div className={styles.emojiList}>
        {emojiList.map(([name, char]) => {
          if (!searchTerms.every((term) => name.includes(term))) return null;

          return (
            <button
              type="button"
              className={styles.emojiBtn}
              key={name}
              title={name}
              onClick={() => props.onSelect(name)}
            >
              {char}
            </button>
          );
        })}
      </div>
    </div>
  );
}

const styles = require('./EmojiMenu-styles.module.scss');
