
import { ChangeEventHandler, FocusEventHandler } from 'react';
import { cn } from '../helpers';

interface Props {
  id?: string;
  className?: string;
  disabled?: boolean;
  value?: string;
  defaultValue?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  readOnly?: boolean;
  placeholder?: string;
  type?: string;
}

export default function Input(props: Props) {
  return (
    <input
      type={props.type || 'text'}
      className={cn(styles.input, props.className)}
      id={props.id}
      defaultValue={props.defaultValue}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      disabled={props.disabled}
      readOnly={props.readOnly}
      placeholder={props.placeholder}
    />
  );
}

const styles = require('./Input-styles.module.scss');
