
import { forwardRef, MouseEventHandler, ReactElement, ReactNode } from 'react';
import { cn } from '../helpers';

interface Props {
  label: string;
  disabled?: boolean;
  dark?: boolean;
  className?: string;
  headerButton?: boolean;
  icon?: ReactElement;
  onClick?: MouseEventHandler;
  children?: ReactNode;
}

export default forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { icon, children, label, dark, headerButton, className, ...restProps } = props;

  const classes = cn(styles.button, dark && styles.dark, headerButton && styles.headerButton, className);

  return (
    <button
      {...restProps}
      ref={ref}
      type="button"
      className={classes}
      aria-label={label}
      title={label}
    >
      <div className={styles.icon} aria-hidden="true">
        {icon}
      </div>
    </button>
  );
});

const styles = require('./IconButton-styles.module.scss');
