

interface Props {
  text: string;
}

export default function ModalHeader(props: Props) {
  return (
    <header className={styles.header}>
      <h1 className={styles.heading}>{props.text}</h1>
    </header>
  );
}

const styles = require('./ModalHeader-styles.module.scss');
