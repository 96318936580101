
import { ReactNode } from 'react';
import { cn } from '../helpers';

interface Props {
  row?: boolean;
  children: ReactNode;
}

export default function ModalBody(props: Props) {
  return <main className={cn(styles.main, props.row && styles.row)}>{props.children}</main>;
}

const styles = require('./ModalBody-styles.module.scss');
