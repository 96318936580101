
import { MouseEventHandler, ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { cn } from '../helpers';

interface Props {
  bold?: boolean;
  disabled?: boolean;
  href?: string;
  icon?: ReactElement;
  leftIcon?: ReactElement;
  onClick?: MouseEventHandler;
  flavor?: 'confirm' | 'danger';
  type?: 'submit' | 'button';
  children: ReactNode;
  submitting?: boolean;
  className?: string;
  submittingText?: string;
  style?: object;
}

export default function Button(props: Props) {
  const {
    bold,
    icon,
    leftIcon,
    children,
    href,
    type = 'button',
    submitting,
    submittingText,
    className,
    ...restProps
  } = props;

  const fullClassName = cn(
    styles.button,
    props.bold && styles.bold,
    props.flavor && styles[props.flavor],
    className
  );

  if (href) {
    return (
      <Link to={href} {...restProps} className={fullClassName}>
        {submitting ? submittingText || 'Saving...' : children}
        {icon && (
          <div className={styles.icon} aria-hidden="true">
            {icon}
          </div>
        )}
      </Link>
    );
  }

  return (
    <button {...restProps} type={type} className={fullClassName}>
      {leftIcon && (
        <div className={styles.leftIcon} aria-hidden="true">
          {leftIcon}
        </div>
      )}
      {submitting ? submittingText || 'Saving...' : children}
      {icon && (
        <div className={styles.icon} aria-hidden="true">
          {icon}
        </div>
      )}
    </button>
  );
}

const styles = require('./Button-styles.module.scss');
