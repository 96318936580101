
import { ReactNode } from 'react';

interface Props {
  name: string;
  children: ReactNode;
}

export default function DropdownGroup(props: Props) {
  return (
    <ul className={styles.group} aria-label={props.name}>
      <div className={styles.name}>{props.name}</div>
      {props.children}
    </ul>
  );
}

const styles = require('./DropdownGroup-styles.module.scss');
