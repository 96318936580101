
import { useState } from 'react';
import { emojis } from '../emojis';
import Button from './Button';
import EmojiMenu from './EmojiMenu';

interface Props {
  selected: string;
  onSelect: (emojiName: string) => void;
}

export default function EmojiPicker(props: Props) {
  const [expanded, setExpanded] = useState(false);

  let popup = null;

  if (expanded) {
    popup = (
      <EmojiMenu
        onSelect={(name) => {
          setExpanded(false);
          name && props.onSelect(name);
        }}
      />
    );
  }

  return (
    <div className={styles.container}>
      <Button className={styles.triggerBtn} onClick={() => setExpanded(!expanded)}>
        {emojis[props.selected]}
      </Button>

      {popup}
    </div>
  );
}

const styles = require('./EmojiPicker-styles.module.scss');
