
import {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEvent,
  useLayoutEffect,
  useRef,
} from 'react';
import { cn } from '../helpers';

interface Props {
  autofocus?: boolean;
  id?: string;
  className?: string;
  label?: string;
  disabled?: boolean;
  value?: string;
  defaultValue?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  readOnly?: boolean;
  placeholder?: string;
  onEnter?: () => any;
  onBlur?: FocusEventHandler;
}

function calculateRows(node: HTMLTextAreaElement | null) {
  if (!node) return 1;
  const lineHeight = 18;
  node.rows = 1;
  const rows = Math.max(1, Math.min(node.scrollHeight / lineHeight));
  node.rows = rows;
  return rows;
}

export default function Textarea(props: Props) {
  const ref = useRef<HTMLTextAreaElement>(null);

  useLayoutEffect(() => {
    if (props.autofocus && ref.current) {
      ref.current.selectionStart = ref.current.value.length;
      ref.current.focus();
      calculateRows(ref.current);
    }
  }, [props.autofocus]);

  function onKeyPress(evt: KeyboardEvent) {
    if (evt.key === 'Enter' && props.onEnter) {
      evt.preventDefault();
      props.onEnter();
    }
  }

  const rows = calculateRows(ref.current);

  return (
    <textarea
      ref={ref}
      className={cn(styles.textarea, props.className)}
      id={props.id}
      value={props.value}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      disabled={props.disabled}
      readOnly={props.readOnly}
      placeholder={props.placeholder}
      rows={rows}
      onKeyPress={onKeyPress}
      onBlur={props.onBlur}
    />
  );
}

const styles = require('./Textarea-styles.module.scss');
